<!--
 * @Description: 客房消费列表
 * @Author: 琢磨先生
 * @Date: 2024-04-25 16:31:55
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-25 16:51:45
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>

  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" @sort-change="onSort" border>
      <el-table-column
        label="消费项"
        prop="item_name"
        width="200"
      ></el-table-column>
      <el-table-column label="金额" width="140">
        <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column label="日期" width="150" prop="date"></el-table-column>
      <el-table-column
        label="备注"
        min-width="200"
        prop="remark"
      ></el-table-column>
      <el-table-column label="订单" width="150" prop="order.type_text">
      </el-table-column>
      <el-table-column label="单号" width="200" prop="order.order_no">
        <template #default="scope">
          <el-link
            type="primary"
            :underline="false"
            v-if="is_can_dtl"
            @click="onOrderDtl(scope.row.order)"
            >{{ scope.row.order.order_no }}</el-link
          >
          <div v-else>{{ scope.row.order.order_no }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="渠道"
        width="150"
        prop="order.ota_name"
      ></el-table-column>
      <el-table-column
        label="房号"
        width="150"
        prop="store.house_no"
      ></el-table-column>
      <el-table-column
        label="创建日期"
        width="200"
        prop="create_at"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>
  <order-dtl v-if="current_order_id" :order_id="current_order_id"></order-dtl>
</template>

<script>
import OrderDtl from "../order/order_dtl.vue";

export default {
  components: {
    OrderDtl,
  },
  data() {
    return {
      loading: false,

      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //当前订单id
      current_order_id: null,
    };
  },
  created() {
    this.loadData();
    this.is_can_dtl = this.$power("seller/v1/houseOrder/dtl");
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.$http
        .post("seller/v1/houseOrderConsumption", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
          this.loading = false;
        });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 查看订单详情
     * @param {*} o
     */
    onOrderDtl(o) {
      this.current_order_id = o.id;
    },
  },
};
</script>

<style scoped></style>
